// Function to ensure environment variables are set
const getEnvVariable = (value: string | undefined, key: string): string => {
  if (!value) {
    throw new Error(
      `Cannot fetch from Strapi. Environment variable ${key} is not set in .env file.`
    );
  }
  return value;
};

// Check and get environment variables
const apiHost = getEnvVariable(
  process.env.NEXT_PUBLIC_API_HOST,
  'NEXT_PUBLIC_API_HOST'
);
const apiToken = getEnvVariable(
  process.env.NEXT_PUBLIC_API_TOKEN,
  'NEXT_PUBLIC_API_TOKEN'
);

export const api = {
  get: async (url: string, version: string) => {
    // console.log('fetch', `${apiHost}/api${url}`);
    const response = await fetch(`${apiHost}/api${url}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        version,
      },
    });

    if (!response.ok) {
      const data = await response.json();
      if (data.error) {
        throw new Error(
          `Error fetching data: ${data.error.status}. Description: ${data.error.message}`
        );
      } else {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
    }

    const data = await response.json();
    return { data };
  },
  getAge: async () => {
    const avgAgeRes = await fetch(`${apiHost}/api/capis/getAvgYear`);
    const avgAge = await avgAgeRes.text();
    return avgAge;
  },
};
