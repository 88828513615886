import { Marquee } from 'components/marquee';

import { Clienttype } from '../selfContainedComponents/clients/clients';
import styles from './styles.module.scss';

export type ClientsMarqueeProps = {
  clients: Clienttype[];
};

export function ClientsMarquee({ clients }: ClientsMarqueeProps) {
  return (
    <Marquee className={styles.marquee} itemClassName={styles.marqueeItem}>
      {clients.map((client) => (
        <img
          className={styles.clientLogo}
          key={client.name}
          src={client.img}
          alt={client.name}
          title={client.name}
        />
      ))}
    </Marquee>
  );
}
