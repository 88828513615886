import Image from 'next/image';
import { useState } from 'react';

import { AbstractButton } from '../../internalComponents/abstractButton/abstractButton';
import { GrowingArrowButton } from '../../internalComponents/growingArrowButton/growingArrowButton';
import { Button } from '../button/button';
import { Heading } from '../heading/heading';
import { Paragraph } from '../paragraph/paragraph';
import styles from './tile.module.scss';
import { TileProps } from '@/types/tileProps';

export const Tile = ({
  orientation,
  href,
  image,
  title,
  subtitle,
  category,
  date,
  copy,
  buttonLabel,
  buttonLink,
}: TileProps) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const postDate = date ? new Date(date) : undefined;

  const renderButton = () => {
    if (buttonLabel && (buttonLink || href)) {
      return (
        <Button
          className={styles.externalLink}
          style="underline"
          href={buttonLink || href}
          icon="ArrowNorthEast"
        >
          {buttonLabel}
        </Button>
      );
    }
    if (href) {
      return (
        <GrowingArrowButton className={styles.externalLink} hover={hovering} />
      );
    }
    return <></>;
  };

  const tileContent = () => (
    <article className={`${styles.tile} ${styles[orientation]}`}>
      <div
        className={`${styles.imageContainer} ${
          hovering ? styles.hovering : ''
        } ${href ? styles.square : styles.tall}`}
      >
        <Image
          fill
          className={styles.image}
          src={image ?? ''}
          alt={title}
          sizes="(max-width: 639px) 100vw,
              (max-width: 1279px) 50vw,
              33vw"
        />
      </div>
      <div className={styles.content}>
        <Heading level="h3" style="regular" tag="h2">
          {title}
        </Heading>
        {subtitle && (
          <Heading className={styles.subtitle} level="h3" style="sub" tag="h3">
            {subtitle}
          </Heading>
        )}
        {(category || date) && (
          <div className={styles.postInfo}>
            {category && (
              <Heading
                className={styles.category}
                level="h3"
                style="sub"
                tag="h3"
              >
                {category}
              </Heading>
            )}
            {postDate && (
              <Heading className={styles.date} level="h3" style="sub" tag="h3">
                <time dateTime={postDate.toISOString()}>
                  {postDate.toLocaleDateString('nl-NL', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </time>
              </Heading>
            )}
          </div>
        )}
        {copy && <Paragraph style="regular">{copy}</Paragraph>}
        {renderButton()}
      </div>
    </article>
  );

  return (
    <>
      {href && !buttonLabel ? (
        <AbstractButton
          href={href}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className={styles.container}
        >
          {tileContent()}
        </AbstractButton>
      ) : (
        tileContent()
      )}
    </>
  );
};
