'use client';

import { Paragraph } from '@/components/commonComponents/paragraph/paragraph';
import { usePathname } from 'next/navigation';

import { useEffect, useRef, useState } from 'react';
import { Filters } from '@/components/internalComponents/filters/filters';
import { Container } from '../../pageComponents/container/container';
import { TileList, TileListProps } from '../tileList/tileList';
import { Loader } from '@/components/internalComponents/loader/loader';
import { getPosts } from '@/helpers/api/getPosts';
import { mapTiles } from '@/helpers/app-router-migration/mapTiles';
import { Pagination } from '@/components/internalComponents/pagination/pagination';
import { FilterType } from '@/types/misc';

// TODO: Refactor this type with PostProps
export interface BlogPostsProps extends TileListProps {
  filters?: FilterType[];
  currentFilter: string;
  pageCount: number;
  currentPage: number;
}

export const BlogPosts = ({
  filters,
  tiles: initialTiles = [],
  maxPerRow = 2,
  pageCount: initialPageCount,
  currentPage: initialPage,
  currentFilter: initialCategory,
}: BlogPostsProps) => {
  const pathname = usePathname();

  const preventInitialRender = useRef(true);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(initialPageCount);
  const [category, setCategory] = useState(initialCategory);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [tiles, setTiles] = useState(initialTiles);
  const [currentFilter, setCurrentFilter] = useState({ key: '', value: '' });

  useEffect(() => {
    const getPostsStrapi = async (newCategory: string, newPage: number) => {
      setLoading(true);

      const filters =
        newCategory !== 'alle'
          ? {
              category: {
                $eqi: newCategory,
              },
            }
          : undefined;

      const posts = await getPosts({
        strapiResponseVersion: '2',
        params: {
          sort: 'publishDate:desc',
          pagination: {
            pageSize: 10,
            page: newPage ?? 1,
          },
          populate: {
            thumbnail: {
              fields: ['url'],
            },
          },
          filters,
        },
      });

      const tiles = mapTiles(posts.data);

      // Replace window state with new category and page
      const searchParams = new URLSearchParams();
      searchParams.set('category', newCategory);
      searchParams.set('page', newPage.toString());
      window.history.replaceState(
        {},
        '',
        `${pathname}?${searchParams.toString()}`
      );

      setPageCount(posts.pageCount);
      setTiles(tiles as any);
      setLoading(false);
    };

    if (!preventInitialRender.current) getPostsStrapi(category, currentPage);
    if (preventInitialRender.current) preventInitialRender.current = false;
  }, [category, currentPage]);

  useEffect(() => {
    // On unmount, set preventInitialRender to true
    // Fix for react strictmode
    return () => {
      preventInitialRender.current = true;
    };
  }, []);

  useEffect(() => {
    if (currentFilter.value === '') return;
    if (currentFilter.value.toLowerCase() === category.toLowerCase()) return;

    if (currentFilter.value) {
      setCategory(currentFilter.value.toLowerCase());
      setCurrentPage(1);
      // Set to true to enable getPostsStrapi to fetch new posts
    }
  }, [currentFilter]);

  return (
    <>
      {filters && filters.length > 0 && (
        <Container size="wide">
          <Filters
            filters={filters}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
          />
        </Container>
      )}
      {loading ? (
        <Container size="narrow" layoutBlock="centered">
          <Loader />
        </Container>
      ) : (
        <>
          {tiles.length > 0 ? (
            <TileList tiles={tiles} maxPerRow={maxPerRow} />
          ) : (
            <Paragraph style="large" contained={false}>
              Er zijn nog geen berichten in deze categorie.
            </Paragraph>
          )}
        </>
      )}

      {pageCount > 1 && !loading && (
        <Container size="wide">
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      )}
    </>
  );
};
