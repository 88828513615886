'use client';
import { MediaEmbed } from '../mediaEmbed/mediaEmbed';

export interface CastEpisodeProps {
  title: string;
  description: string;
  spotifyEmbed: string;
}

export const CastEpisode = ({
  title,
  description,
  spotifyEmbed,
}: CastEpisodeProps) => {
  return (
    <MediaEmbed
      mediaType="spotify"
      underlay
      media={{
        source: spotifyEmbed,
      }}
      title={title}
      copy={description}
    />
  );
};
