import { TileList } from '@/types/tileProps';
import { PostV2 } from '@/types/post';

export const mapTiles = (data: PostV2[]): TileList[] => {
  return data.map((post: PostV2) => ({
    id: post.id.toString(),
    image: post.attributes.thumbnail.data.attributes.url,
    title: post.attributes.title,
    category: post.attributes.category,
    date: post.attributes.publishedAt,
    href: `/nieuws${post.attributes.slug}`,
  }));
};
