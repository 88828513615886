import qs from 'qs';

import { api } from './apiBaseUrl';

import { GetReqMultiple } from '@/types/getRequests';

export const fetchMultipleEntries = async <T,>({
  collectionType,
  locale = 'nl',
  params,
  strapiResponseVersion = '1',
}: { collectionType: string } & GetReqMultiple): Promise<T> => {
  const query = qs.stringify({
    ...params,
    depth: params?.depth ?? 2,
    locale,
  });

  try {
    const { data: strapiData } = await api.get(
      `/${collectionType}?${query}`,
      strapiResponseVersion
    );

    return {
      data: strapiData.data,
      pageCount:
        strapiResponseVersion === '1'
          ? strapiData.pagination.pageCount
          : strapiData.meta.pagination.pageCount,
    } as T;
  } catch (error) {
    throw new Error(`Failed to fetch data from ${collectionType}: ${error}`);
  }
};
