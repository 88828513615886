'use client';
import {
  Testimonial,
  TestimonialProps,
} from '@/components/commonComponents/testimonial/testimonial';
import { Container } from '@/components/pageComponents/container/container';

import { TextBlock } from '../textBlock/textBlock';
import styles from './styles.module.scss';

export type TestimonialGridProps = {
  title: string;
  copy: string;
  testimonials: TestimonialProps[];
};

export function TestimonialGrid({
  testimonials,
  title,
  copy,
}: TestimonialGridProps) {
  return (
    <Container size="xWide">
      <TextBlock title={title} copy={copy} className={styles.header} />
      <div className={styles.grid}>
        {testimonials.map((testimonial) => (
          <div className={styles.item} key={testimonial.name}>
            <Testimonial
              key={testimonial.name}
              {...testimonial}
              display="grid"
            />
          </div>
        ))}
      </div>
    </Container>
  );
}
