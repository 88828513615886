'use client';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Icon, Icons } from '@/components/internalComponents/icon';
import { Container } from '@/components/pageComponents/container/container';
import { TextBlock } from '@/components/selfContainedComponents/textBlock/textBlock';

import { ListItem, ListItemProps } from 'components/list-item';

import {
  Illustration,
  Illustrations,
} from '../../internalComponents/illustration/illustration';
import styles from './simpleList.module.scss';

export type Item = Omit<ListItemProps, 'leadingAddon' | 'className'> & {
  illustration?: keyof typeof Illustrations;
};

export interface SimpleListProps {
  simpleListHeading: string;
  headingStyle?: 'regular' | 'compact';
  copy?: string;
  icon?: keyof typeof Icons;
  counter?: boolean;
  items?: Item[];
  cols?: 1 | 2;
}

export const SimpleList = ({
  simpleListHeading,
  headingStyle = 'regular',
  copy,
  items = [],
  icon,
  counter,
  cols = 1,
}: SimpleListProps) => {
  const leadingAddon = (item: Item, index: number) => {
    if (icon) {
      return <Icon name={icon} className={styles.icon} />;
    }
    if (item.illustration) {
      return (
        <Illustration
          name={item.illustration}
          className={styles.illustration}
        />
      );
    }
    if (counter) {
      return `${index + 1}`.padStart(2, '0');
    }
    return null;
  };

  return (
    <>
      {copy ? (
        <TextBlock
          title={simpleListHeading}
          copy={copy}
          headingStyle={headingStyle}
          headingLevel={headingStyle === 'regular' ? 'h2' : 'h3'}
          paragraphStyle="large"
        />
      ) : (
        <Container size="narrow">
          <Heading
            style={headingStyle}
            level={headingStyle === 'regular' ? 'h2' : 'h3'}
            tag="h2"
          >
            {simpleListHeading}
          </Heading>
        </Container>
      )}
      <Container size={cols === 1 ? 'narrow' : 'wide'}>
        <ul
          className={`${styles.simpleList} ${
            counter || (items && items.some((item) => item.illustration))
              ? styles.largeGap
              : ''
          } ${cols === 2 ? styles.cols : ''}`}
        >
          {items.map((item, index) => (
            <ListItem
              key={item.heading}
              {...item}
              leadingAddon={leadingAddon(item, index)}
            />
          ))}
        </ul>
      </Container>
    </>
  );
};
