'use client';
import { Button } from '@/components/commonComponents/button/button';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Paragraph } from '@/components/commonComponents/paragraph/paragraph';
import { VideoPlayer } from '@/components/internalComponents/videoPlayer/videoPlayer';
import { Container } from '@/components/pageComponents/container/container';
import { TextBlock } from '@/components/selfContainedComponents/textBlock/textBlock';
import Image from 'next/image';
import sanitize from 'sanitize-html';

import styles from './mediaEmbed.module.scss';
import { ButtonType, MediaType } from '@/types/misc';

export interface MediaEmbedProps extends ButtonType {
  className?: string;
  mediaType: 'portrait' | 'landscape' | 'spotify' | 'fit';
  underlay?: boolean;
  title?: string;
  copy?: string;
  // TODO: Backend fix needed - Strapi returns either .media prop or .thumbnail.url prop, depending on depth parameter
  //  Very sloppy and variable behaviour of API
  thumbnail?: {
    url: string;
  };
  media: MediaType;
  sourceLink?: string;
  direction?: 'ltr' | 'rtl';
}

export const MediaEmbed = ({
  className,
  mediaType,
  underlay,
  title,
  copy,
  media,
  sourceLink,
  buttonLabel,
  buttonLink,
  direction = 'rtl',
}: MediaEmbedProps) => {
  const containerSize = () => {
    if (mediaType === 'landscape' && title && copy) {
      return 'xWide';
    }
    return 'wide';
  };

  const containerLayout = () => {
    if (mediaType === 'spotify') {
      return 'cast';
    }
    if (title && copy && mediaType === 'landscape') {
      return 'media';
    }
    if ((title || copy) && mediaType === 'fit') {
      return 'list';
    }
    return undefined;
  };

  const renderMedia = () => {
    const imgExtensions = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'bmp'];
    const isImage = imgExtensions.some((ext: string) =>
      media.source?.endsWith(`.${ext}`)
    );

    if (sourceLink?.includes('youtu')) {
      const videoId = sourceLink?.match(/(\?v=|be\/)(.+)/)?.[2];
      return (
        <iframe
          className={`${styles.mediaVideo} ${styles[mediaType]}`}
          src={`https://www.youtube-nocookie.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; encrypted-media; gyroscope"
          allowFullScreen
        />
      );
    }

    return isImage ? (
      <div className={`${styles.mediaImage} ${styles[mediaType]}`}>
        <Image src={media.source} alt="poster" fill />
      </div>
    ) : (
      <VideoPlayer
        className={`${styles.mediaVideo} ${styles[mediaType]}`}
        source={media.source}
        thumbnail={media.thumbnail ?? ''}
      />
    );
  };

  const renderTextBlock = () =>
    copy ? (
      <TextBlock
        contained={false}
        title={title}
        copy={`${copy}`}
        buttonLabel={buttonLabel}
        buttonLink={buttonLink}
      />
    ) : (
      <Heading style="regular" level="h2" tag="h2">
        {title}
      </Heading>
    );

  return (
    <Container size={containerSize()} layoutBlock={containerLayout()}>
      {['landscape', 'fit'].includes(mediaType) &&
        (title || copy) &&
        direction === 'rtl' &&
        renderTextBlock()}
      <div
        className={`${styles.mediaEmbed} ${styles[mediaType]} ${
          underlay ? styles.underlay : ''
        } ${className ?? ''}`}
      >
        {mediaType === 'spotify' ? (
          <iframe
            className={styles.mediaSpotify}
            title={sanitize(`${title}`, { allowedTags: [] })}
            // src={`${media.source}?theme=0`}
            src={`https://open.spotify.com/embed/episode/${media.source}?theme=0`}
            allowFullScreen={false}
            allow="clipboard-write; encrypted-media"
          />
        ) : (
          <>
            {underlay && (
              <div
                className={styles.underlayment}
                style={{ backgroundImage: `url(${media.thumbnail})` }}
              />
            )}
            {renderMedia()}
            {mediaType === 'portrait' && (copy || buttonLabel) && (
              <div className={styles.content}>
                {copy && <Paragraph style="large">{copy}</Paragraph>}

                {buttonLabel && (
                  <Button
                    style="underline"
                    icon="ArrowNorthEast"
                    href={buttonLink}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {mediaType === 'spotify' && (
        <div className={styles.spotifyContent}>
          <Heading level="h3" style="regular" tag="h2">
            {title}
          </Heading>
          <Paragraph style="regular">{`${copy}`}</Paragraph>
        </div>
      )}
      {['landscape', 'fit'].includes(mediaType) &&
        (title || copy) &&
        direction === 'ltr' &&
        renderTextBlock()}
    </Container>
  );
};
